import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {Link} from '../common/Links';
import Fuse from 'fuse.js'
import Loading from 'common/Loading'
import Keyvisual from 'common/Keyvisual'
import {PublicationList} from 'common/CategoryProductList'

import {useQuery, useSearch} from 'hooks'
import {queryGetSearch} from 'graph'


export default function Search()
{
	const {data, loading} = useQuery(queryGetSearch)
	const keyword = useSearch('keyword', '')

	if (loading) {
		return <Loading text="Suche läuft ..."/>
	}

	const publications = data?.publications || []
	const collections  = data?.collections || []

	const products = [...publications, ...collections];
	const fuse = new Fuse(products, {
		keys: [
			{
				name: 'number',
				weight: 3
			},
			{
				name: 'name',
				weight: 2
			},
			'title'
		]
	})

	const result = fuse.search(keyword, {
		limit: 20,
	}).map(({item}) => item);

	return (
		<>
			<Keyvisual/>
			<Container as="section">
				<Row className="breadcrumb-wrapper">
					<Col>
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<Link to="/" title="Startseite">
										<span>Startseite</span>
									</Link>
								</li>
								<li className="breadcrumb-item">
									<a href="#search" onClick={e => e.preventDefault()}>
										<span>Suchergebnis</span>
									</a>
								</li>
							</ol>
						</nav>
					</Col>
				</Row>
				<Row>
					<Col>
						<header>
							<h4>Suchergebnis</h4>
							<h1>Ihre Suche nach <strong>{keyword}</strong> lieferte folgendes Ergebnis</h1>
						</header>
					</Col>
				</Row>
				<Row>
					<Col>
						{result.length === 0 && (
							<p className="lead">Leider konnten keine Inhalte für Ihre Suche nach <strong>{keyword}</strong> gefunden werden.</p>
						)}
						{result.length > 0 && (
							<PublicationList 
								placeholder="Ihre Suche verfeinern"
								publications={result} 
								loading={false}
								disableFilter
							/>
						)}
					</Col>
				</Row>
			</Container>
		</>
	)
}