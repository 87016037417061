import React from 'react'
import {Row, Col, Carousel, Card} from 'react-bootstrap'
import cover from 'img/cover_ddbs.jpg'

export function SlideDDBS()
{
	return (
		<>
			<img
				className="d-block w-100"
				src={cover}
				alt="VdS-BrandSchutzTalk"
			/>
			<Carousel.Caption>
				<h1 className="d-none d-lg-block text-left text-white text-shadow">
					Gratis-Intro!
				</h1>
				<Row className="mb-0">
					<Col>
						<Card className="h-100">
							<Card.Body>
								<a href="https://bildung.vds.de/de/brandschutz/dokumentation-einfach-digital/12468?utm_source=webshop&utm_medium=slider&utm_campaign=EL-DDBS" target="_blanc">
									<h5 className="d-none d-lg-block">Kostenlose Einführung in die<br/>digitale Dokumentation für Brandschutz<br/>und andere Sicherheitsbereiche</h5>
									<h5 className="d-lg-none">Kostenlose Einführung in die digitale Dokumentation</h5>
								</a>
							</Card.Body>
						</Card>
					</Col>
					<Col>
					</Col>
				</Row>
			</Carousel.Caption>
		</>
	)
}