import React, {useState} from 'react'
import {Row, Col, Alert} from 'react-bootstrap'
import PayPalModal from './PayPalModal' 
import Loading from 'common/Loading'
import {ExternalForm, Hidden, useForm} from 'common/Form'
import Button from 'common/Button'
import {useTranslation, Trans} from 'react-i18next';
import {useUser, useQuery, useMutation} from 'hooks'
import {queryBasketOrderSimulate, queryGetContextBasket, mutationBasketOrderCreate} from 'graph'
import {Package, CreditCard, File} from 'react-feather'
import {DownloadIcon, ShippingIcon} from 'common/Icons'
import {formatPrice, calcVat} from 'utils'

const IconCreditCard = () => <CreditCard className="vds-button-img" size={28}/>
const IconInvoice = () => <File className="vds-button-img" size={28}/>
const IconAddress = () => <Package className="vds-button-img" size={28}/>

function Positions({basket})
{
	const {t} = useTranslation('basket'); 
	return (
		<table className="table">
			<thead>
				<tr>
					<th scope="col">{t('payment.table.position')}</th>
					<th scope="col">{t('payment.table.description')}</th>
					<th scope="col" className="text-center">{t('payment.table.vat')}</th>
					<th scope="col" className="text-right">{t('payment.table.quantity')}</th>
					<th scope="col" className="text-right">{t('payment.table.total')}</th>
				</tr>
			</thead>
			<tbody>
				{basket.positions.map(position => (
					<Position key={position.number} position={position}/>)
				)}
			</tbody>
			<tfoot>
				{basket.shippingAmount > 0 && (
					<tr>
						<td className="text-right" colSpan="5">
							<div>{t('payment.table.shipping', {price: formatPrice(basket.shippingAmount)})}</div>
						</td>
					</tr>
				)}
				<tr>
					<td className="text-right"  colSpan="5">
						<h4>{t('payment.table.total_netto', {price: formatPrice(basket.amount + basket.shippingAmount)})}</h4>
					</td>
				</tr>
				<tr>
					<td className="text-right" colSpan="5">
						<div>{t('payment.table.total_vat', {vat: formatPrice(basket.taxes)})}</div>
						{basket.shippingTaxes > 0 && (
							<div>{t('payment.table.shipping_vat', {vat: formatPrice(basket.shippingTaxes)})}</div>
						)}
					</td>
				</tr>
				<tr>
					<td className="text-right"  colSpan="5">
						<h4>{t('payment.table.total_brutto', {price: formatPrice(basket.amount + basket.taxes + basket.shippingAmount + basket.shippingTaxes)})}</h4>
					</td>
				</tr>
			</tfoot>
		</table>
	)
}

function ShippingBatch({download})
{
	const {t} = useTranslation('basket');
	return download ? (
		<span>{t('payment.batch.download')} <DownloadIcon style={{position: 'relative', top: -3}} size={17}/></span>
	) : (
		<span>{t('payment.batch.shipping')} <ShippingIcon  style={{position: 'relative', top: -3}} size={17}/></span>
	)
}

function Position({position})
{
	const {number, quantity, taxes, total, product, download} = position
	return (
		<tr>
			<th scope="row">{number}</th>
			<td>
				<h5>
					<small className="text-muted">{product.name} - <ShippingBatch download={download}/></small>
					<br/>
					{product.title}
				</h5>
			</td>
			<td align="center">{calcVat(total, taxes)}</td>
			<td align="right">{quantity}</td>
			<td align="right">{formatPrice(total)}&nbsp;EUR</td>
		</tr>
	)
}

export default function Payment({next, prev}) {
	const {t} = useTranslation('basket');
	const {data, error: simulationError, loading: simulationLoading} = useQuery(queryBasketOrderSimulate, {
		fetchPolicy: 'network-only',
		onError() {
			console.log('An error occured while simulating sales order')
		}
	})
	const [doOrder, {error: orderError, loading: orderLoading}] = useMutation(mutationBasketOrderCreate, {
		refetchQueries: [
			{query: queryGetContextBasket}
		],
		onError() {
			console.log('An error occured while creating salesorder')
		}
	})
	const [showPayPal, setShowPayPal] = useState(false)
	const user = useUser()
	const loading = simulationLoading || orderLoading
	const basket = data?.basketSimulate

	const methods = useForm()
	const onSubmit = input => doOrder({variables: {input}}).then(({data}) => next(data.basketOrderCreate))

	const setExternalOrderNumber = number => {
		methods.setValue('externalOrderNumber', number)

		const doSubmit = methods.handleSubmit(onSubmit)
		doSubmit()
	}

	const getTotal = () => {
		if (!basket) {
			return null
		}

		return basket.amount + basket.taxes + basket.shippingAmount + basket.shippingTaxes
	}

	const canPayOnline = !!(basket && !basket.items.some(item => item.subscription))
	const canPayInvoice = !!(user.customer || getTotal() === 0)

	if (simulationError || orderError) {
		return (
			<div className="vds-quicklinks-box my-0">
				<div className="vds-quicklinks-box-header">
					<h1 className="text-danger">{t('payment.alert.error.title')}</h1>
				</div>
				<div className="row py-1 text-danger border-danger">
					<div className="col">
						<Trans t={t} i18nKey="payment.alert.error.body">
							Bitte wenden Sie sich direkt an unsere Hotline <a className="alert-link text-danger" href="callto:+492217766">+49 (0)221 77 66 122</a>
							{' '}
							oder senden Sie uns eine E-Mail an <a className="alert-link text-danger" href="mailto:verlag@vds.de">verlag@vds.de</a> um Ihre Bestellung manuell aufzugeben.
						</Trans>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div>
			<h2 className="mb-3">{t('payment.title')}</h2>
			<PayPalModal 
				show={showPayPal} 
				setExternalOrderNumber={setExternalOrderNumber}
				total={() => basket.amount + basket.taxes + basket.shippingAmount + basket.shippingTaxes}
				onHide={() => setShowPayPal(false)}
			/>
			<Row>
				{loading && (
					<Col className="text-center">
						{simulationLoading && <Loading text={t('payment.loading.price_simulation')}/>}
						{orderLoading && <Loading text={t('payment.loading.create_order')}/>}
					</Col>
				)}
				{!loading && (
					<Col>
						<Positions basket={basket}/>
					</Col>
				)}
			</Row>

			{!loading && (!canPayInvoice || !canPayOnline) && (
				<Row>
					<Col>
						{!loading && !canPayInvoice && (
							<Alert variant="warning">
								<Alert.Heading>{t('payment.alert.invoice.title')}</Alert.Heading>
								<p className="mb-0">{t('payment.alert.invoice.body')}</p>
							</Alert>
						)}
						{!loading && !canPayOnline && (
							<Alert variant="warning">
								<Alert.Heading>{t('payment.alert.paypal.title')}</Alert.Heading>
								<p className="mb-0">{t('payment.alert.paypal.body')}</p>
							</Alert>
						)}
					</Col>
				</Row>
			)}
			<ExternalForm
				onSubmit={onSubmit}
				methods={methods}
			>
				<Hidden
					name="externalOrderNumber"
				/>
			
				<Row>
					<Col xs={12} sm={4}>
						<Button 
							onClick={prev}
							icon={<IconAddress/>}
						>{t('payment.prev')}</Button>
					</Col>
					<Col xs={12} sm={4}>
						<Button 
							disabled={!canPayOnline || loading || getTotal() === 0}
							onClick={() => setShowPayPal(true)}
							icon={<IconCreditCard/>}
						>{t('payment.next_paypal')}</Button>
					</Col>
					<Col xs={12} sm={4}>
						<Button 
							type="submit"
							disabled={!canPayInvoice || loading}
							icon={<IconInvoice/>}
						>{getTotal() === 0 ? t('payment.next_free') : t('payment.next_invoice')}</Button>
					</Col>
				</Row>
			</ExternalForm>
		</div>
	)
}