import React from 'react'
import {Row, Col, Carousel, Card} from 'react-bootstrap'
//import {Link} from 'common/Links'
import cover from 'img/cover_themis.jpg'

export function SlideThemis()
{
	return (
		<>
			<img
				className="d-block w-100"
				src={cover}
				alt="Neue Publikationen"
			/>
			<Carousel.Caption>
				<h1 className="d-none d-lg-block text-left text-white text-shadow">
					VdS-THEMIS
				</h1>
				<Row className="mb-0">
					<Col>
						<Card className="h-100">
							<Card.Body className="d-flex flex-wrap align-content-between">
								<a href="https://www.vds-themis.de/" target="_blanc">
									<h5 className="d-none d-lg-block">Mit THEMIS sparen Sie Zeit und haben Ihre Dokumentation sicher im Griff!</h5>
									<h5 className="d-lg-none">Mit THEMIS sparen Sie Zeit</h5>
								</a>
							</Card.Body>
						</Card>
					</Col>
					<Col>
						{/*
						<Card className="h-100">
							<Card.Body className="d-flex flex-wrap align-content-between">
								<Link to="/publikation/vds-cea-4001">
									<h5 className="d-none d-lg-block">VdS CEA-Richtlinien für Sprinkleranlagen, Planung und Einbau</h5>
									<h5 className="d-lg-none">VdS CEA-Richtlinien für Sprinkleranlagen</h5>
								</Link>
							</Card.Body>
						</Card>
						*/}
					</Col>
				</Row>
			</Carousel.Caption>
		</>
	)
}